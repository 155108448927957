import React from 'react';
import { Helmet } from 'react-helmet';
import { MainContainer } from '../../styledComponents/MainContainer';
import { Content } from '../../styledComponents/Content';
import { Container } from '../../styledComponents/Container';
import { GlobalStyle } from '../../styledComponents/GlobalStyle';
import Footer from '../Footer/Footer';  // Import Footer

const About = () => {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Snapchat Story Downloader Viewer | Download & Save Stories Easily</title>
                <meta name="description" content="StoryCloner.com is tool to download snapchat stories. You can view and save snapchat story on your device of any public snapchat profile." />
                <meta name="keywords" content="Snapchat story downloader, download snapchat story, snapchat story saver" />
                <meta name="author" content="Ryan Percy" />
                <meta property="og:title" content="Snapchat Story Downloader Viewer | Download & Save Stories Easily" />
                <meta property="og:description" content="Save and download snapchat story on your device" />
              {/* <meta property="og:image" content="URL to your image (if available)" /> */}
                <meta property="og:type" scontent="website" />
                <meta property="og:url" content="https://storycloner.com/about" />
                <link rel="canonical" href="https://storycloner.com/about" />
            </Helmet>

            <GlobalStyle />
            <MainContainer>
                <Content>
                    <Container>
                        {/* Main Heading */}
                        <h1>Snapchat Story Downloader</h1>
                        <p>
                            Snapchat story downloader offers to save and view snapchat stories and highlights of anyone on your device as long as it is a public profile.
                        </p>

                        {/* Subheading */}
                        <h2>Download snap stories</h2>
                        <p>
                           If you view stories or highlights of a public profile on your account and end up liking the story or highlights and wanted to <a href="https://storycloner.com">download snapchat highlights</a> on your device, this tool is for you. 
                        </p>


                        {/* Add internal or external links (SEO Tip) */}
                        <p>
                            To learn more about our services, feel free to email us at storycloner@gmail.com for more information And visit our site <a href="https://storycloner.com">Snapchat viewer</a>.
                        </p>
                    </Container>
                </Content>
            </MainContainer>

            <Footer />  {/* Include the footer */}
        </>
    );
};

export default About;
