import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppRoutes from '../Routes/AppRoutes';
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your GA tracking ID
const TRACKING_ID = "G-DFJ6QFE8X3";  // Replace with your actual GA4 tracking ID

// Component to handle Google Analytics page view tracking
function Analytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);  // Initialize GA once
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });  // Send pageview on route change
  }, [location]);

  return null;  // This component doesn't render anything
}

function App() {
  return (
    <BrowserRouter>
      <Analytics />  {/* Include Analytics component for tracking page views */}
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
