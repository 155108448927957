import React from 'react';
import { MainContainer } from '../../../styledComponents/MainContainer';
import { GlobalStyle } from '../../../styledComponents/GlobalStyle';
import { Content } from '../../../styledComponents/Content';
import { Container } from '../../../styledComponents/Container';
import { DownloadButton, DownloadIcon } from '../../../styledComponents/Button';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ReactGA from 'react-ga4'; 

// Styling for the media preview wrapper to center content and position the download button

const TRACKING_ID = "G-DFJ6QFE8X3";  // Replace with your GA4 tracking ID
ReactGA.initialize(TRACKING_ID);


const MediaPreviewWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px;
`;

// Styling for the media preview (image) with yellow border
const MediaPreview = styled.img`
    width: 100%;
    height: auto;
    display: block;
    border: 5px solid yellow;  /* Add yellow border */
    border-radius: 8px;        /* Optional: Rounded corners */
`;

// Position the download button relative to the media preview
const PositionedDownloadButton = styled(DownloadButton)`
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
`;

// Heading styles
const SectionHeading = styled.h2`
    margin: 20px 0;
    text-align: center;
    font-size: 24px;
`;

// Container for stories and highlights
const SectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;

function SnapProfile() {
    const location = useLocation();
    const { stories = [], highlights = [] } = location?.state ?? {};

    // Helper function to download the full media (video or image)
    const handleDownload = async (url, filename) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
    
            // Determine file extension based on content type or URL
            let fileExtension = 'mp4'; // Default to video if no extension is provided
    
            // Check content type from headers, or fallback to file extension in URL
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("image")) {
                if (contentType.includes("jpeg")) {
                    fileExtension = 'jpg';
                } else if (contentType.includes("png")) {
                    fileExtension = 'png';
                }
            } else if (url.includes('.jpg') || url.includes('.jpeg')) {
                fileExtension = 'jpg';
            } else if (url.includes('.png')) {
                fileExtension = 'png';
            } else if (url.includes('.mp4')) {
                fileExtension = 'mp4';
            }
    
            // Create the download link with the correct extension
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${filename}.${fileExtension}`;  // Use the detected extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the media:', error);
            alert('Error downloading the media. Please try again.');
        }
    };

    return (
        <>
            <GlobalStyle />
            <MainContainer>
                <Content>
                    <Container>

                        {/* Stories Section */}
                        {stories.length > 0 && (
                            <>
                                <SectionHeading>Stories</SectionHeading>
                                <SectionContainer>
                                    {stories.map((media, i) => (
                                        <MediaPreviewWrapper key={i}>
                                            {/* Display media preview (thumbnail) */}
                                            <MediaPreview
                                                src={media.media_preview_url}
                                                alt={`Snapchat Story Preview ${i}`}
                                            />

                                            {/* Circular Download button positioned on the preview */}
                                            <PositionedDownloadButton onClick={() => handleDownload(media.media_url, `snapchat_story_${i}`)}>
                                                <DownloadIcon />
                                            </PositionedDownloadButton>
                                        </MediaPreviewWrapper>
                                    ))}
                                </SectionContainer>
                            </>
                        )}

                        {/* Highlights Section */}
                        {highlights.length > 0 && (
                            <>
                                <SectionHeading>Highlights</SectionHeading>
                                <SectionContainer>
                                    {highlights.map((media, i) => (
                                        <MediaPreviewWrapper key={i}>
                                            {/* Display media preview (thumbnail) */}
                                            <MediaPreview
                                                src={media.media_preview_url}
                                                alt={`Snapchat Highlight Preview ${i}`}
                                            />

                                            {/* Circular Download button positioned on the preview */}
                                            <PositionedDownloadButton onClick={() => handleDownload(media.media_url, `snapchat_highlight_${i}`)}>
                                                <DownloadIcon />
                                            </PositionedDownloadButton>
                                        </MediaPreviewWrapper>
                                    ))}
                                </SectionContainer>
                            </>
                        )}

                    </Container>
                </Content>
                <Footer/>
            </MainContainer>
        </>
    );
}

export default SnapProfile;
