import React from 'react';
import { useRoutes } from 'react-router-dom';
import Home from '../Home/Home';
import SnapProfile from '../App/Profile/SnapProfile';
import AboutMe from '../Pages/AboutMe';

function AppRoutes() {
    const routes = useRoutes([
        { path: '/', element: <Home /> },
       { path: '/about', element: <AboutMe /> },
        { path: '/profile/:username', element: <SnapProfile /> },
    ]);

    return routes;
}

export default AppRoutes;
