import styled from 'styled-components';

export const Input = styled.input`
  padding: 20px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 80%;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;