import styled from 'styled-components';

export const FooterText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #ddd;

  @media (max-width: 768px) {
    font-size: 12px;
  }
a:link {
  color: yellow;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: yellow;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: orange;
  background-color: transparent;
}
a:active {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}
`;